import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get indication details
const GET_INDICATION_OUTPUT = 'GET_INDICATION_OUTPUT';
const GET_INDICATION_OUTPUT_SUCCESS = 'GET_INDICATION_OUTPUT_SUCCESS';
const GET_INDICATION_OUTPUT_FAILURE = 'GET_INDICATION_OUTPUT_FAILURE';
const GET_MORE_INDICATION_OUTPUT = 'GET_MORE_INDICATION_OUTPUT';
const GET_MORE_INDICATION_OUTPUT_SUCCESS = 'GET_MORE_INDICATION_OUTPUT_SUCCESS';

export const getIndicationOutputAction = createAction(GET_INDICATION_OUTPUT);

const getIndicationOutputSuccess = createAction(GET_INDICATION_OUTPUT_SUCCESS);

export const getMoreIndicationOutputAction = createAction(GET_MORE_INDICATION_OUTPUT);

const getMoreIndicationOutputSuccess = createAction(GET_MORE_INDICATION_OUTPUT_SUCCESS);

const getIndicationOutputFailure = createAction(GET_INDICATION_OUTPUT_FAILURE);

export const getIndicationOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_INDICATION_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/indicationData?${action.payload}`, 'get')).pipe(
    map((res) => getIndicationOutputSuccess(res)),
    catchError((err) => of(getIndicationOutputFailure(err))),
  )),
);
export const getMoreIndicationOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_INDICATION_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/indicationData?${action.payload}`, 'get')).pipe(
    map((res) => getMoreIndicationOutputSuccess(res)),
    catchError((err) => of(getIndicationOutputFailure(err))),
  )),
);
export const getIndicationOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getIndicationOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getIndicationOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreIndicationOutputAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreIndicationOutputSuccess, (state, action) => {
        state.data = {
          totalCount: action.payload.response.data.totalCount,
          data: {
            ...state.data.data,
            extractedProtienData: [...state.data.data.extractedProtienData, ...action.payload.response.data.extractedProtienData],
          },
        };

        state.loading = false;
        state.flag = true;
      })
      .addCase(getIndicationOutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// graph data
const GET_GRAPH_DATA = 'GET_GRAPH_DATA';
const GET_GRAPH_DATA_SUCCESS = 'GET_GRAPH_DATA_SUCCESS';
const GET_GRAPH_DATA_FAILURE = 'GET_GRAPH_DATA_FAILURE';

export const getGraphDataAction = createAction(GET_GRAPH_DATA);

const getGraphDataSuccess = createAction(GET_GRAPH_DATA_SUCCESS);

const getGraphDataFailure = createAction(GET_GRAPH_DATA_FAILURE);

export const getGraphDataEpic = (actions$) => actions$.pipe(
  ofType(GET_GRAPH_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/graphData?${action.payload}`, 'get')).pipe(
    map((res) => getGraphDataSuccess(res)),
    catchError((err) => of(getGraphDataFailure(err))),
  )),
);
export const getGraphDataReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getGraphDataAction, (state) => {
        state.loading = true;
      })
      .addCase(getGraphDataSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getGraphDataFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
