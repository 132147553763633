import { combineEpics } from 'redux-observable';
import { requestDemoEpic } from './ModalRequestDemo/logic';
import { checkStatusEpic } from '../../modules/meta-ctp/pages/CTPInputPage/logic';
import { getCTPOutputEpic, getMoreCTPOutputEpic, isReadCTPOutputEpic } from '../../modules/meta-ctp/pages/CTPOutputPage/logic';
import { generateLinkersOutputEpic } from '../../modules/dc/pages/DCOutputPage/logic';
import { generateLinkersEpic } from '../../modules/dc/components/InputDropdown/logic';
import { generatePayloadsEpic } from '../../modules/adc/components/InputDropdown/logic';
import { generateADCOutputResponseEpic } from '../../modules/adc/pages/ADCOutputPage/logic';
import { getStudyGroupsEpic } from '../../modules/biomarker/container/SelectStudy/logic';
import { getTerminalsEpic, usersMeEpic } from './Selection/logic';
import { getInputSuggestEpic } from '../../modules/iep/components/InputDropdown/logic';
import {
  generateIndicationsEpic, getInputSynonymsEpic, iepchCheckStatusEpic, uploadCCADocumentEpic,
} from '../../modules/iep/pages/IEPInputPage/logic';
import { checkBiomarkerStatusEpic, getBiomarkerJobIdEpic } from '../../modules/biomarker/pages/BiomarkerInputPage/logic';
import {
  getBiomarkerOutputEpic, getMoreBiomarkerOutputEpic, isReadBiomarkerOutputEpic, volcanoGraphBiomarkerOutputEpic,
} from '../../modules/biomarker/pages/BiomarkerOutputPage/logic';
import { generateTIInputsEpic } from '../../modules/target-id/components/InputDropdown/logic';
import { getInputSuggestDREpic } from '../../modules/dr/components/InputDropdown/logic';
import { drCheckStatusEpic, generateIndicationsDREpic } from '../../modules/dr/pages/DRInputPage/logic';
import { getIndicationOutputDREpic, isReadDROutputEpic } from '../../modules/dr/pages/DROutputPage/logic';
import { checkTIStatusEpic, generateTITargetEpic } from '../../modules/target-id/pages/TargetIdInputPage/logic';
import { isReadTIOutputEpic } from '../../modules/target-id/pages/TargetIdOutputPage/logic';
import { checkSARStatusEpic } from '../../modules/sar/pages/SARInputPage/logic';
import { isReadSAROutputEpic } from '../../modules/sar/pages/SAROutputPage/logic';
import { getInputSuggestProtacEpic } from '../../modules/protac/components/InputDropdown/logic';
import { protacCheckStatusEpic, generateDesignProtacEpic } from '../../modules/protac/pages/ProtacInputPage/logic';
import { getMoreProtacOutputEpic, getProtacOutputEpic, isReadProtacOutputEpic } from '../../modules/protac/pages/ProtacOutputPage/logic';
import { uploadSmileDocumentEpic } from '../../modules/sar/container/InputBox/logic';
import { getAutoSuggestEpic } from '../../modules/biomarker/container/BiomarkerInputComponents/SearchDatasets/logic';
import { getFiltersEpic, getSampleFiltersEpic } from '../../modules/biomarker/pages/NewBiomarkerInputPage/logic';
import { getMoreStudiesEpic, getStudiesEpic } from '../../modules/biomarker/container/BiomarkerInputComponents/SelectPreferredStudies/logic';
import { getMoreSamplesEpic, getSamplesEpic } from '../../modules/biomarker/container/BiomarkerInputComponents/ViewSamples/logic';
import { ctpPhaseDistributionEpic } from '../../modules/meta-ctp/container/Graph/BarGraph/logic';
import { ctpKolEpic } from '../../modules/meta-ctp/container/Graph/SlopeGraph/logic';
import { ctpCountryDistributionEpic } from '../../modules/meta-ctp/container/Graph/BubbleGraphContainer/logic';
import { ctpWordCloudEpic } from '../../modules/meta-ctp/container/Graph/WordCloud/logic';
import {
  geoCtDetailsEpic, geoCtEpic, geoCtStatesEpic, geoKolDetailsEpic, geoKolEpic,
  geoKolStatesEpic,
} from '../../modules/meta-ctp/container/Graph/GlobalMap/logic';
import { getMoreSAROutputEpic, getSAROutputEpic } from '../../modules/sar/pages/LeadDetailsPage/logic';
import { getAdmetOutputEpic } from '../../modules/sar/pages/AdmetPropertiesPage/logic';
import {
  getGraphDataEpic, getIndicationOutputEpic, getMoreIndicationOutputEpic,
} from '../../modules/iep/pages/TopIndicationsPage/logic';
import { checkAdmetSARStatusEpic, getAdmetCompareOutputEpic, isReadAdmetSAROutputEpic } from '../../modules/sar/components/AdmetProperties/CompareMoleculeView/logic';
import { getPathwayEnrichmentEpic } from '../../modules/iep/components/TopIndicationGraphs/PathwayEnrichment/logic';
import { getAssociatedProteinsDistributionEpic, getMoreAssociatedProteinsDistributionEpic } from '../../modules/iep/components/TopIndicationGraphs/AssociatedProteinDistributionGraph/logic';
import { getPPINetworkGraphEpic } from '../../modules/iep/components/TopIndicationGraphs/PPINetworkGraph/logic';
import { getLiteratureMiningEpic } from '../../modules/iep/components/TopIndicationGraphs/LiteratureMiningGraph/logic';
import { getMoreTIOutputEpic, getTIOutputEpic } from '../../modules/target-id/pages/DiseaseBasedPage/logic';
import { getClinicalTrialEnrichmentEpic } from '../../modules/iep/components/TopIndicationGraphs/EnrichmentGraph/logic';
import { iepEditCheckStatusEpic } from '../../modules/iep/container/EditAssociatedProteinView/logic';
import { getTargetVisibilityOutputEpic } from '../../modules/target-id/container/TargetVisibilityView/logic';
import { getSafetyProfileOutputEpic } from '../../modules/target-id/container/SafetyProfileView/logic';
import { getDrugDevelopmentOutputEpic } from '../../modules/target-id/container/DrugDevelopmentFeasilibilityView/logic';
import { getTargetFeatureNetworkVisualisationOutputEpic } from '../../modules/target-id/components/DiseaseBasedGraphs/TargetFeaturesNetworkGraph/logic';
import { getTopOrganAffectedOutputEpic } from '../../modules/target-id/container/TopOrganAffectedView/logic';
import { isReadIEPOutputEpic } from '../../modules/iep/pages/IEPOutputPage/logic';
import { getSynonymsEpic, iepAdvanceSearchCheckStatusEpic } from '../../modules/iep/container/AdvanceSearchView/logic';
import {
  clinicalAnalysisJobsIEPEpic, getMoreClinicalAnalysisJobsIEPEpic, getMoreTopIndicationsJobsIEPEpic, iepUpdateWorkspaceStatusEpic, iepWorkspaceStatusEpic, topIndicationsJobsIEPEpic,
} from '../../modules/iep/pages/MyWorkspace/logic';
import { addProteinsFileInputEpic, addProteinsTextualInputEpic } from '../../modules/iep/container/AddProteinsModal/logic';
import { ctpUpdateWorkspaceStatusEpic, hctpWorkspaceStatusEpic, posWorkspaceStatusEpic } from '../../modules/meta-ctp/pages/MyWorkspace/logic';
import { hctpDrugIndicationsEpic } from '../../modules/meta-ctp/pages/HCTPOutputPage/logic';
import { generateHCTPCombinationsEpic, generateInsightsEpic, hctpPairsFileInputEpic } from '../../modules/meta-ctp/container/SaveProjectModal/logic';
import {
  sarAdmetPropertiesWorkspaceStatusEpic, sarDrugPredictionWorkspaceStatusEpic, sarLeadGenerationWorkspaceStatusEpic, sarMoreLeadGenerationWorkspaceStatusEpic, sarUpdateWorkspaceStatusEpic,
} from '../../modules/sar/pages/MyWorkspace/logic';
import { generateSARLeadEpic } from '../../modules/sar/container/SaveProjectModal/logic';
import { generateCTPInputsEpic } from '../../modules/meta-ctp/components/InputDropdown/logic';
import { aeVerifySequencesEpic } from '../../modules/antibody-engineering/pages/AntibodyEngineeringInputPage/logic';
import { aeGetSequencesEpic } from '../../modules/antibody-engineering/components/LightChainOutputComponents/RawDataAndDockingComponent/logic';
import { getPhaseDistributionOutputEpic } from '../../modules/target-id/container/PhaseDistributionView/logic';
import { diseaseBasedJobsTIEpic, getMorediseaseBasedJobsTIEpic, tiUpdateWorkspaceStatusEpic } from '../../modules/target-id/pages/MyWorkspace/logic';
import { getExpressionDataEpic } from '../../modules/iep/components/TopIndicationGraphs/ExpressionGraph/logic';
import {
  getMoreOncoTaggingOutputEpic, getOncoTaggingOutputEpic, getUtilitiesPathwayEnrichmentEpic, getUtilitiesPPINetworkEpic, pathwayEnrichmentUploadFileEpic, ppiNetworkUploadFileEpic,
} from '../../modules/utilities/pages/OncoTaggingInputPage/logic';
import { getDPOutputEpic } from '../../modules/sar/pages/DrugTargetPredictionPage/logic';

const rootAppEpic = combineEpics(
  requestDemoEpic,
  getTerminalsEpic,
  generateInsightsEpic,
  getCTPOutputEpic,
  getMoreCTPOutputEpic,
  generateLinkersEpic,
  generateLinkersOutputEpic,
  generatePayloadsEpic,
  generateADCOutputResponseEpic,
  checkStatusEpic,
  isReadCTPOutputEpic,
  usersMeEpic,
  getStudyGroupsEpic,
  getInputSuggestEpic,
  generateIndicationsEpic,
  iepchCheckStatusEpic,
  getIndicationOutputEpic,
  isReadIEPOutputEpic,
  checkBiomarkerStatusEpic,
  getBiomarkerJobIdEpic,
  getBiomarkerOutputEpic,
  getMoreBiomarkerOutputEpic,
  isReadBiomarkerOutputEpic,
  volcanoGraphBiomarkerOutputEpic,
  generateTIInputsEpic,
  generateTITargetEpic,
  checkTIStatusEpic,
  getTIOutputEpic,
  isReadTIOutputEpic,
  getInputSuggestDREpic,
  drCheckStatusEpic,
  generateIndicationsDREpic,
  getIndicationOutputDREpic,
  isReadDROutputEpic,
  generateSARLeadEpic,
  checkSARStatusEpic,
  getSAROutputEpic,
  getAdmetOutputEpic,
  isReadSAROutputEpic,
  checkAdmetSARStatusEpic,
  isReadAdmetSAROutputEpic,
  getAdmetCompareOutputEpic,
  getInputSuggestProtacEpic,
  protacCheckStatusEpic,
  generateDesignProtacEpic,
  getProtacOutputEpic,
  isReadProtacOutputEpic,
  getMoreIndicationOutputEpic,
  getMoreProtacOutputEpic,
  getMoreTIOutputEpic,
  getGraphDataEpic,
  uploadSmileDocumentEpic,
  getMoreSAROutputEpic,
  getAutoSuggestEpic,
  getFiltersEpic,
  getStudiesEpic,
  getMoreStudiesEpic,
  getSampleFiltersEpic,
  getSamplesEpic,
  getMoreSamplesEpic,
  ctpPhaseDistributionEpic,
  ctpKolEpic,
  ctpWordCloudEpic,
  geoCtEpic,
  ctpCountryDistributionEpic,
  geoKolEpic,
  getPathwayEnrichmentEpic,
  getAssociatedProteinsDistributionEpic,
  getMoreAssociatedProteinsDistributionEpic,
  getPPINetworkGraphEpic,
  getLiteratureMiningEpic,
  getClinicalTrialEnrichmentEpic,
  generateCTPInputsEpic,
  iepEditCheckStatusEpic,
  getTargetVisibilityOutputEpic,
  getSafetyProfileOutputEpic,
  getDrugDevelopmentOutputEpic,
  getTargetFeatureNetworkVisualisationOutputEpic,
  getTopOrganAffectedOutputEpic,
  generateCTPInputsEpic,
  getSynonymsEpic,
  iepAdvanceSearchCheckStatusEpic,
  getInputSynonymsEpic,
  iepWorkspaceStatusEpic,
  addProteinsTextualInputEpic,
  addProteinsFileInputEpic,
  uploadCCADocumentEpic,
  hctpWorkspaceStatusEpic,
  posWorkspaceStatusEpic,
  topIndicationsJobsIEPEpic,
  clinicalAnalysisJobsIEPEpic,
  hctpDrugIndicationsEpic,
  generateHCTPCombinationsEpic,
  ctpUpdateWorkspaceStatusEpic,
  getMoreTopIndicationsJobsIEPEpic,
  getMoreClinicalAnalysisJobsIEPEpic,
  hctpPairsFileInputEpic,
  sarLeadGenerationWorkspaceStatusEpic,
  sarMoreLeadGenerationWorkspaceStatusEpic,
  sarAdmetPropertiesWorkspaceStatusEpic,
  sarUpdateWorkspaceStatusEpic,
  getOncoTaggingOutputEpic,
  getMoreOncoTaggingOutputEpic,
  aeVerifySequencesEpic,
  aeGetSequencesEpic,
  getPhaseDistributionOutputEpic,
  diseaseBasedJobsTIEpic,
  getMorediseaseBasedJobsTIEpic,
  tiUpdateWorkspaceStatusEpic,
  iepUpdateWorkspaceStatusEpic,
  geoCtStatesEpic,
  geoCtDetailsEpic,
  geoKolStatesEpic,
  geoKolDetailsEpic,
  getExpressionDataEpic,
  getUtilitiesPathwayEnrichmentEpic,
  getUtilitiesPPINetworkEpic,
  ppiNetworkUploadFileEpic,
  pathwayEnrichmentUploadFileEpic,
  sarDrugPredictionWorkspaceStatusEpic,
  getDPOutputEpic,
);

export default rootAppEpic;
